// See docs/loadable-components.md for details regarding **.imports-X.** file extension & duplicate file structure.
import React from 'react';
import ReactDOM from 'react-dom';
import { loadableReady } from '@loadable/component';

import AppContainer from './AppContainer';

import { ReactOnRailsContext } from 'contexts/AppContext/types';
import { AppProps } from './app';

const App = (props: AppProps, railsContext: ReactOnRailsContext, domNodeId: string) => {
  loadableReady(() => {
    ReactDOM.hydrate(
      React.createElement(AppContainer, { ...props, railsContext }),
      document.getElementById(domNodeId),
    );
  });
};

export default App;
