import * as React from 'react';
import ScrollToTop from 'react-router-scroll-top';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';
import { ReactOnRailsContext } from 'contexts/AppContext/types';
import App, { AppProps } from './app';
import HelmetContextContainer from 'contexts/HelmetContext/HelmetContextContainer';

interface AppContainerProps extends AppProps {
  railsContext: ReactOnRailsContext;
}

const AppContainer = ({ ...props }: AppContainerProps) => {
  // SSR
  if (typeof window === 'undefined') {
    return (
      <HelmetContextContainer includeContext>
        <StaticRouter location={props.railsContext.location} context={{}}>
          <App {...props} />
        </StaticRouter>
      </HelmetContextContainer>
    );
  }
  let honeybadger;
  // Ignore Feefo widget error
  const ignoreNotice = notice =>
    (notice.name === 'window.onerror' &&
      notice.message === 'ResizeObserver loop completed with undelivered notifications.' &&
      notice.stack.includes(':0:0')) ||
    (notice.backtrace && notice.backtrace.some(trace => trace.file && trace.file.includes('feefo')));
  Honeybadger.beforeNotify(notice => {
    if (ignoreNotice(notice)) {
      return false;
    }
    return true;
  });

  if (process.env.NODE_ENV === 'production') {
    const config = {
      apiKey: process.env.HONEYBADGER_API_KEY,
      environment: process.env.IS_STAGING === 'true' ? 'staging' : 'production',
      revision: process.env.GIT_SHA,
      filters: ['confirmation'],
    };

    honeybadger = Honeybadger.configure(config);
  } else {
    honeybadger = Honeybadger.configure({
      apiKey: null,
    });
  }

  return (
    <HelmetContextContainer>
      <HoneybadgerErrorBoundary honeybadger={honeybadger}>
        <BrowserRouter
          basename="/"
          getUserConfirmation={() => {
            /* Empty callback to block the default browser prompt */
          }}
        >
          <ScrollToTop />
          <App {...props} />
        </BrowserRouter>
      </HoneybadgerErrorBoundary>
    </HelmetContextContainer>
  );
};

export default AppContainer;
